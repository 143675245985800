@use "sass:color";

:root {
  --font-sans: var(--font-poppins);
}

$palette-light-colors: (
  primary: oklch(54.32% 0.1518 265.8),
  secondary: oklch(66.79% 0.1096 240.06),
  accent: oklch(0.698 0.128 172.22),
  info: oklch(59.46% 0.1398 250.3),
  success: oklch(63.42% 0.1283 156.2),
  warning: oklch(0.706 0.153 69.655),
  error: oklch(61.37% 0.2039 25.56),
  neutral: oklch(14% 0.005 285.823),
);

@mixin generate-color-variants($name, $color) {
  --color-#{$name}: #{$color};
  --color-#{$name}-light: #{color.adjust($color, $lightness: 5%)};
  --color-#{$name}-dark: #{color.adjust($color, $lightness: -5%)};
  --color-#{$name}-content: var(--color-base-100);
}

[data-theme="jamloop-light"] {
  // Palette colors
  @each $name, $color in $palette-light-colors {
    @include generate-color-variants($name, $color);
  }

  // Background
  --color-base-100: oklch(100% 0 0);
  --color-base-200: oklch(98% 0 0);
  --color-base-300: oklch(95% 0 0);

  // Text
  --color-base-content: var(--color-text-primary);
  --color-text-primary: oklch(35% 0.0126 267.19);
  --color-text-secondary: oklch(60% 0.0126 267.19);
}
